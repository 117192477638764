<template>
  <div class="testError">
    <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
    <div class="testError_main">
      <div class="testError_main_header">
        <div class="testError_main_header_left">
          <p>错题本</p>
          <p>
            <el-progress
              :percentage="Number(Number(testIndex + 1)/Number(total))*100"
              :show-text="false"
            ></el-progress>
          </p>
        </div>
        <div class="testError_main_header_right">
          <!-- <span @click="showAnalysisFun" v-if="isShowAnalysis==false">答案解析</span>
          <span @click="noShowAnalysisFun" v-if="isShowAnalysis==true">隐藏解析</span>
          <span @click="del()">删除</span>-->
          <!-- <p class="collectPage_main_header_right_view">
            <img src="@/assets/exam/calculator.png" alt />
            <span>计算器</span>
          </p> -->
          <p class="collectPage_main_header_right_wire"></p>
          <p class="collectPage_main_header_right_view" @click="back">
            <img src="@/assets/exam/mistakes_block.png" style="cursor: pointer;" alt />
            <span style="margin-left:0px;">返回错题本</span>
          </p>
          <p class="collectPage_main_header_right_wire"></p>
          <p class="collectPage_main_header_right_view">
            <img src="@/assets/exam/backCollect.png"  style="cursor: pointer;" @click="showAnalysisFun"  v-if="isShowAnalysis==false" alt />
            <img src="@/assets/exam/backCollect.png"  style="cursor: pointer;" @click="noShowAnalysisFun"  v-if="isShowAnalysis==true" alt />
            <span style="margin-left:0px;" @click="showAnalysisFun" v-if="isShowAnalysis==false">答案解析</span>
             <span style="margin-left:0px;" @click="noShowAnalysisFun" v-if="isShowAnalysis==true">隐藏解析</span>
          </p>
          <p class="collectPage_main_header_right_wire"></p>
          <p class="collectPage_main_header_right_view"  style="cursor: pointer;" @click="del()">
            <img src="@/assets/exam/removeCollect.png" alt />
            <span style="margin-left:0px;" @click="del()">删除</span>
          </p>
        </div>
      </div>
      <div class="testError_main_testView">
        <div class="testError_main_testView_icon"></div>
        <div class="testError_main_testView_tips" v-if="pageExamObj.classify==1">单项选择题</div>
        <div class="testError_main_testView_tips" v-if="pageExamObj.classify==2">多项选择题</div>
        <div class="testError_main_testView_tips" v-if="pageExamObj.classify==3">判断题</div>
        <div class="testError_main_testView_tips" v-if="pageExamObj.classify==4">不定项选择题</div>
        <div class="testError_main_testView_tips" v-if="pageExamObj.classify==5">计算分析题</div>
        <div class="testError_main_testView_tips" v-if="pageExamObj.classify==6">综合题</div>
        <div class="testError_main_testView_tips" v-if="pageExamObj.classify==7">简答题</div>
        <div class="testError_main_testView_tips" v-if="pageExamObj.classify==8">计算回答题</div>
        <div class="testError_main_testView_tips" v-if="pageExamObj.classify==9">案例分析题</div>
        <div class="testError_main_testView_tips" v-if="pageExamObj.classify==10">计算题</div>
        <div class="testError_main_testView_tips" v-if="pageExamObj.classify==11">综合分析题</div>
        <div class="testError_main_testView_title">{{pageExamObj.subjectTitle}}</div>
        <div class="testError_main_testView_options" v-if="pageExamObj.classify<4">
          <el-checkbox-group v-model="checkList" v-if="pageExamObj.classify==2">
            <el-checkbox
              class="f-v-c"
              v-for="(item,index) in pageExamObj.answerList"
              :key="index"
              :label="item.answerId"
            >
              {{item.indexes}}、{{item.answerName}}
              <img
                :src="item.answerPic"
                class="answerPic"
                alt
                v-if="item.type==2"
              />
            </el-checkbox>
          </el-checkbox-group>

          <el-radio-group v-model="radio" v-if="pageExamObj.classify==1 || pageExamObj.classify==3">
            <el-radio
              class="f-v-c"
              v-for="(item,index) in pageExamObj.answerList"
              :key="index"
              :label="item.answerId"
            >
              {{item.indexes}}、
              <span v-if="item.type==1">{{item.answerName}}</span>
              <img :src="item.answerPic" alt class="answerPic" v-if="item.type==2" />
            </el-radio>
          </el-radio-group>
          <!-- <p v-for="(item,index) in pageExamObj.answerList" :key="index">
                        
                        <span>{{item.indexes}}</span><span v-if="item.type==1">{{item.answerName}}</span>
                        <span v-if="item.type==2"><img :src="item.answerPic" alt=""></span>
          </p>-->
        </div>
        <div class="testError_main_testView_options" v-else>
          <div
            class="testError_main_testView_options_box"
            v-if="pageExamObj.subjectList!=null && pageExamObj.subjectList.length > 0"
          >
            <div class="testError_main_testView_options_box_header" style>
              <span
                v-for="(item,index) in pageExamObj.subjectList"
                :key="index"
                @click="tabSmallQuestion(item,index)"
                :class="smallQuestionIndex==item.subjectId?'checked':''"
              >第{{index+1}}小题</span>
            </div>
            <div class style="margin-bottom:10px;">{{smallQuestionObj.subjectTitle}}</div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                class="f-v-c"
                v-for="(checkItem,key) in smallQuestionObj.answerList"
                :key="key"
                :label="checkItem.answerId"
              >
                {{checkItem.indexes}}、{{checkItem.answerName}}
                <img
                  :src="checkItem.answerPic"
                  class="answerPic"
                  alt
                  v-if="checkItem.type==2"
                />
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <div class v-else>
            <el-checkbox-group v-model="checkList" v-if="pageExamObj.classify==4">
              <el-checkbox
                class="f-v-c"
                v-for="(item,index) in pageExamObj.answerList"
                :key="index"
                :label="item.answerId"
              >
                {{item.indexes}}、{{item.answerName}}
                <img
                  :src="item.answerPic"
                  class="answerPic"
                  alt
                  v-if="item.type==2"
                />
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="testError_main_testView_judge" v-if="isShowAnalysis==true">
          <img src="@/assets/exam/error.png" alt />
          <div class="testError_main_testView_judge_left">
            <span>您的答案：</span>
            <span style="color:#F25E64;">{{ myAnswer_new }}</span>
          </div>
          <div class="testError_main_testView_judge_right">
            <span>参考答案：</span>
            <p v-for="(item,index) in pageExamObj.answerList" :key="index">
              <span v-if="item.type==1 && item.isRight==1" style="color:#4ECB8A;">{{item.indexes}}</span>
              <img :src="item.pic" alt v-if="item.type==2 && item.isRight==1" />
            </p>
          </div>
          <div class="testError_main_testView_judge_right">
            <span>全站正确率：</span>
            <p>{{questionObj.totalAccuracy}}</p>
          </div>
        </div>
        <div class="testError_main_testView_analysis" v-if="isShowAnalysis==true">
          <!-- <div class="testError_main_testView_analysis_testing">
            <p>
              <span>考</span>
              <span>点：</span>
            </p>
            <p>
              <span
                v-for="(item,index) in pageExamObj.knowledgeList"
                :key="index"
              >{{item.knowledgeName}}</span>
            </p>
          </div>-->
          <div class="testError_main_testView_analysis_content">
            <p style="font-weight:500;">答案解析：</p>
            <p v-html="pageExamObj.analysisText"></p>
            <p
              v-if="pageExamObj.analysisVideo!='' && pageExamObj.analysisVideo!=null && pageExamObj.analysisVideo!=undefined"
            >
              <video :src="pageExamObj.analysisVideo!=''"></video>
            </p>
          </div>
        </div>
        <div class="testError_main_testView_btn" v-if="testList.length>1">
          <el-button @click="prev">上一题</el-button>
          <el-button type="primary" @click="next">下一题</el-button>
        </div>
      </div>
    </div>
    <t_footer></t_footer>
  </div>
</template>

<script>
import t_header from "@/components/t_header/index.vue";
import t_footer from "@/components/t_footer/index.vue";
import { Loading } from "element-ui";
import {
  error_paper_subjectList,
  error_exercise_subjectList,
  del_error
} from "@/api/exam";
import { subject_analysis, questionStatistics } from "@/api/exam.js";
export default {
  name: "testError",
  components: {
    t_header,
    t_footer
  },
  data() {
    return {
      myAnswer_new: "",
      questionObj: {},
      radio: "",
      checkList: [],
      examInfo: {},
      num: "",
      tabIndex: "",
      firstId: "",
      secondId: "",
      tabTwoIindex: "",
      pageExamObj: {},
      testList: [],
      smallQuestionIndex: 0,
      isUserInfo: false,
      testIndex: 0,
      isShowAnalysis: false,
      total: 0,
      smallQuestionObj: {}, //小题的obj
      smallQuestionIndex: 0,
      testTextarea: ""
    };
  },
  mounted() {
    this.examInfo = JSON.parse(this.$route.query.chapterListItem);
    // this.num = this.$route.query.value
    this.tabTwoIindex = this.$route.query.tabTwoIindex;
    // this.firstId = this.$route.query.firstId
    // this.secondId = this.$route.query.secondId
    this.tabIndex = this.$route.query.tabIndex;
    if (this.$route.query.tabTwoIindex == 0) {
      this.error_exercise_subjectList();
    } else {
      this.error_paper_subjectList();
    }
  },
  methods: {
    back(){
            this.$router.go(-1);
        },
    setMyAnwser(list) {
      if (list.answerList && list.answerList.length > 0) {
        if (
          list.doneAnswerIds != null &&
          list.doneAnswerIds.indexOf(",") != -1
        ) {
          let items = list.doneAnswerIds.split(",");
          let val = [];
          items.map(res => {
            list.answerList.map(item => {
              if (res === item.answerId) {
                val.push(item);
              }
            });
          });
          if (val.length == 0) {
            return "未作答";
          } else {
            let str = "";
            val.map(item => {
              str += item.indexes;
            });
            return str;
          }
        } else {
          let val = list.answerList.filter(item => {
            return list.doneAnswerIds === item.answerId;
          });
          if (val.length == 0) {
            return "未作答";
          } else {
            return val[0].indexes;
          }
        }
      }
    },
    initAnalysis() {
      this.loading = Loading.service({
        text: "解析中...",
        background: "rgba(0, 0, 0, 0.1)"
      });
      subject_analysis({ subjectId: this.pageExamObj.subjectId }).then(res => {
        let str = this.setMyAnwser(res.data.data);
        this.myAnswer_new = str;
      });
      questionStatistics({ subjectId: this.pageExamObj.subjectId }).then(
        res => {
          this.questionObj = res.data.data;
          this.loading.close();
        }
      );
    },
    noShowAnalysisFun() {
      this.isShowAnalysis = false;
    },
    showAnalysisFun() {
      this.isShowAnalysis = true;
      this.initAnalysis();
    },
    // 切换小题
    tabSmallQuestion(item, index) {
      this.smallQuestionIndex = item.subjectId;
      this.smallQuestionObj = item;
      // this.isShowAnalysis=false
      if (item.classify == "4") {
        if (
          item.doneAnswerIds != "" &&
          item.doneAnswerIds != null &&
          item.doneAnswerIds != undefined
        ) {
          this.checkList = item.doneAnswerIds.split(",");
        } else {
          this.checkList = [];
        }
      } else {
        if (
          item.doneAnswer != "" &&
          item.doneAnswer != null &&
          item.doneAnswer != undefined
        ) {
          this.testTextarea = item.doneAnswer;
        } else {
          this.testTextarea = "";
        }
      }
    },
    // 删除错题
    del() {
      if (this.testList.length > 0) {
        for (var i in this.testList) {
          if (
            this.testList[Number(i) + 1] == undefined ||
            this.testList[Number(i) + 1] == null
          ) {
            if (this.tabTwoIindex == 0) {
              var obj = {
                subjectId: this.pageExamObj.subjectId,
                nodeId: this.examInfo.nodeId
              };
            } else {
              var obj = {
                subjectId: this.pageExamObj.subjectId,
                testPaperId: this.examInfo.testPaperId
              };
            }
            del_error(obj)
              .then(res => {
                if (res.data.code == 0) {
                  if (this.testList.length > 1) {
                    for (var i in this.testList) {
                      if (
                        this.testList[i].subjectId == this.pageExamObj.subjectId
                      ) {
                        // this.pageExamObj=this.testList[Number(i)+1]
                        this.testList.splice(i, 1);
                        this.total = Number(this.total) - 1;
                        this.pageExamObj = this.testList[0];
                        return false;
                      }
                    }
                  } else {
                    this.$router.go(-1);
                  }
                }
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            if (this.testList[i].subjectId == this.pageExamObj.subjectId) {
              if (this.tabTwoIindex == 0) {
                var obj = {
                  subjectId: this.pageExamObj.subjectId,
                  nodeId: this.examInfo.nodeId
                };
              } else {
                var obj = {
                  subjectId: this.pageExamObj.subjectId,
                  testPaperId: this.examInfo.testPaperId
                };
              }
              del_error(obj)
                .then(res => {
                  if (res.data.code == 0) {
                    // if(this.testList.length>1){
                    for (var i in this.testList) {
                      if (
                        this.testList[i].subjectId == this.pageExamObj.subjectId
                      ) {
                        // this.pageExamObj=this.testList[Number(i)+1]
                        this.testList.splice(i, 1);
                        this.total = Number(this.total) - 1;
                        this.pageExamObj = this.testList[Number(i) + 1];
                        return false;
                      }
                    }
                    // }
                    // else{
                    //     this.$router.go(-1)
                    // }
                  }
                })
                .catch(error => {
                  console.log(error);
                });
            }
          }
        }
      }
    },
    // 父传子
    getTodos(e) {
      this.isUserInfo = e;
    },
    // 章节-答题获取题列表(重做)
    error_exercise_subjectList() {
      var obj = {
        nodeId: this.examInfo.nodeId
      };
      error_exercise_subjectList(obj)
        .then(res => {
          if (res.data.code == 0) {
            this.pageExamObj = res.data.data[0];
            if (this.pageExamObj.classify == "1") {
              for (var i in this.pageExamObj.answerList) {
                if (
                  this.pageExamObj.doneAnswerIds ==
                  this.pageExamObj.answerList[i].answerId
                ) {
                  this.myAnswer = this.pageExamObj.answerList[i].indexes;
                }
              }
            } else if (this.pageExamObj.classify == "2") {
              if (
                this.pageExamObj.doneAnswerIds != "" &&
                this.pageExamObj.doneAnswerIds != null &&
                this.pageExamObj.doneAnswerIds != undefined
              ) {
                var arr = this.pageExamObj.doneAnswerIds.split(",");
                var arr2 = [];
                for (var i in this.pageExamObj.answerList) {
                  for (var j in arr) {
                    if (arr[j] == this.pageExamObj.answerList[i].answerId) {
                      arr2.push(this.pageExamObj.answerList[i].indexes);
                    }
                  }
                }
                this.myAnswer = arr2.join(",");
              } else {
                this.myAnswer = "未作答";
              }
            } else if (this.pageExamObj.classify == "3") {
              if (
                this.pageExamObj.doneAnswerIds != "" &&
                this.pageExamObj.doneAnswerIds != null &&
                this.pageExamObj.doneAnswerIds != undefined
              ) {
                for (var i in this.pageExamObj.answerList) {
                  if (
                    this.pageExamObj.doneAnswerIds ==
                    this.pageExamObj.answerList[i].answerId
                  ) {
                    this.myAnswer = this.pageExamObj.answerList[i].indexes;
                  }
                }
              } else {
                this.myAnswer = "未作答";
              }
            } else if (this.pageExamObj.classify == "4") {
              if (this.pageExamObj.subjectList.length > 0) {
                this.smallQuestionObj = this.pageExamObj.subjectList[0];
                if (
                  this.pageExamObj.subjectList[0].doneAnswerIds != "" &&
                  this.pageExamObj.subjectList[0].doneAnswerIds != null &&
                  this.pageExamObj.subjectList[0].doneAnswerIds != undefined
                ) {
                  var arr = this.pageExamObj.subjectList[0].doneAnswerIds.split(
                    ","
                  );
                  var arr2 = [];
                  for (var i in this.pageExamObj.subjectList[0].answerList) {
                    for (var j in arr) {
                      if (
                        arr[j] ==
                        this.pageExamObj.subjectList[0].answerList[i].answerId
                      ) {
                        arr2.push(
                          this.pageExamObj.subjectList[0].answerList[i].indexes
                        );
                      }
                    }
                  }
                  this.myAnswer = arr2.join(",");
                } else {
                  this.myAnswer = "未作答";
                }
              } else {
                if (
                  this.pageExamObj.doneAnswerIds != "" &&
                  this.pageExamObj.doneAnswerIds != null &&
                  this.pageExamObj.doneAnswerIds != undefined
                ) {
                  var arr = this.pageExamObj.doneAnswerIds.split(",");
                  var arr2 = [];
                  for (var i in this.pageExamObj.answerList) {
                    for (var j in arr) {
                      if (arr[j] == this.pageExamObj.answerList[i].answerId) {
                        arr2.push(this.pageExamObj.answerList[i].indexes);
                      }
                    }
                  }
                  this.myAnswer = arr2.join(",");
                } else {
                  this.myAnswer = "未作答";
                }
              }
            }
            this.testList = res.data.data;
            this.total = res.data.data.length;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 试卷-答题获取题列表(重做)
    error_paper_subjectList() {
      var obj = {
        testPaperId: this.examInfo.testPaperId
      };
      error_paper_subjectList(obj)
        .then(res => {
          if (res.data.code == 0) {
            this.pageExamObj = res.data.data[0];
            if (this.pageExamObj.classify == "1") {
              for (var i in this.pageExamObj.answerList) {
                if (
                  this.pageExamObj.doneAnswerIds ==
                  this.pageExamObj.answerList[i].answerId
                ) {
                  this.myAnswer = this.pageExamObj.answerList[i].indexes;
                }
              }
            } else if (this.pageExamObj.classify == "2") {
              if (
                this.pageExamObj.doneAnswerIds != "" &&
                this.pageExamObj.doneAnswerIds != null &&
                this.pageExamObj.doneAnswerIds != undefined
              ) {
                var arr = this.pageExamObj.doneAnswerIds.split(",");
                var arr2 = [];
                for (var i in this.pageExamObj.answerList) {
                  for (var j in arr) {
                    if (arr[j] == this.pageExamObj.answerList[i].answerId) {
                      arr2.push(this.pageExamObj.answerList[i].indexes);
                    }
                  }
                }
                this.myAnswer = arr2.join(",");
              } else {
                this.myAnswer = "未作答";
              }
            } else if (this.pageExamObj.classify == "3") {
              if (
                this.pageExamObj.doneAnswerIds != "" &&
                this.pageExamObj.doneAnswerIds != null &&
                this.pageExamObj.doneAnswerIds != undefined
              ) {
                for (var i in this.pageExamObj.answerList) {
                  if (
                    this.pageExamObj.doneAnswerIds ==
                    this.pageExamObj.answerList[i].answerId
                  ) {
                    this.myAnswer = this.pageExamObj.answerList[i].indexes;
                  }
                }
              } else {
                this.myAnswer = "未作答";
              }
            } else if (this.pageExamObj.classify == "4") {
              if (this.pageExamObj.subjectList.length > 0) {
                this.smallQuestionObj = this.pageExamObj.subjectList[0];
                if (
                  this.pageExamObj.subjectList[0].doneAnswerIds != "" &&
                  this.pageExamObj.subjectList[0].doneAnswerIds != null &&
                  this.pageExamObj.subjectList[0].doneAnswerIds != undefined
                ) {
                  var arr = this.pageExamObj.subjectList[0].doneAnswerIds.split(
                    ","
                  );
                  var arr2 = [];
                  for (var i in this.pageExamObj.subjectList[0].answerList) {
                    for (var j in arr) {
                      if (
                        arr[j] ==
                        this.pageExamObj.subjectList[0].answerList[i].answerId
                      ) {
                        arr2.push(
                          this.pageExamObj.subjectList[0].answerList[i].indexes
                        );
                      }
                    }
                  }
                  this.myAnswer = arr2.join(",");
                } else {
                  this.myAnswer = "未作答";
                }
              } else {
                if (
                  this.pageExamObj.doneAnswerIds != "" &&
                  this.pageExamObj.doneAnswerIds != null &&
                  this.pageExamObj.doneAnswerIds != undefined
                ) {
                  var arr = this.pageExamObj.doneAnswerIds.split(",");
                  var arr2 = [];
                  for (var i in this.pageExamObj.answerList) {
                    for (var j in arr) {
                      if (arr[j] == this.pageExamObj.answerList[i].answerId) {
                        arr2.push(this.pageExamObj.answerList[i].indexes);
                      }
                    }
                  }
                  this.myAnswer = arr2.join(",");
                } else {
                  this.myAnswer = "未作答";
                }
              }
            } else {
            }
            this.testList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 下一题
    next() {
      if (this.testIndex <= this.testList.length) {
        this.isShowAnalysis = false;
        var index = Number(this.testIndex) + 1;
        this.testIndex = index;
        this.pageExamObj = this.testList[index];
        if (this.testList[index].classify == "4") {
          if (this.testList[index].subjectList.length > 0) {
            this.smallQuestionObj = this.testList[index].subjectList[0];
            this.smallQuestionIndex = this.testList[
              index
            ].subjectList[0].subjectId;
          }
        }
      }
    },
    // 上一题
    prev() {
      if (this.testIndex > 0) {
        this.isShowAnalysis = false;
        console.log(this.testIndex);
        var index = Number(this.testIndex) - 1;
        this.testIndex = index;
        this.pageExamObj = this.testList[index];
      }
    }
  }
};
</script>

<style>
.el-radio-group {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-around;
}
.el-radio {
  margin-bottom: 15px;
  display: flex;
  align-content: center;
}
.el-checkbox-group {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-around;
}
.el-checkbox {
  margin-bottom: 15px;
  display: flex;
  align-content: center;
}
</style>